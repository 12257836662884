import './Content.scss'
import React from 'react'
import {Card, CardTitle, Col, Row} from 'react-materialize'

const ContentItem = () => (
    <Card header={<CardTitle
        image='https://images.unsplash.com/photo-1567570473657-77e2cdb959dc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2852&q=80'/>}
          actions={[<a href='https://google.com' key='some key'>Some action</a>]}>
        Here is the standard card with an image thumbnail.
    </Card>
);

const Content = () => (
    <Row>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
            <Col m={6} s={12} key={i}>
                <ContentItem/>
            </Col>
        ))}
    </Row>
);

export default Content
